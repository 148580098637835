<template>
  <div>
    <br>
    <h5>Problem Description</h5>
    <p class="big mt-2">
      You are given a system of two linear equations.
      $$m_1 x + n_1 y = l_1$$
      $$m_2 x + n_2 y = l_2$$
      Find the number of solutions for the given system of linear equations. conditions.
      <br>
      A system of linear equations can have one, infinitely many, or no solution.
      <ul style="list-style-type:square;">
        <li>
          <h6> Exactly One Solution </h6>
          The system has exactly one solution if:
          $$ \frac{m_1}{m_2} \ne \frac{n_1}{n_2} $$
          Graphically, this means that the two lines described by the two equations intersect exactly at one point.
        </li>
        <li>
          <h6> Infinitely Many Solutions </h6>
          The system has infinitely many solutions if:
          $$ \frac{m_1}{m_2} = \frac{n_1}{n_2} $$ and $$l_1 = l_2$$
          Graphically, this means that the two lines lie on top of each other (overlap).
        </li>
        <li>
          <h6> No Solution </h6>
          The system has no solutions if:
          $$ \frac{m_1}{m_2} = \frac{n_1}{n_2} $$ and $$l_1 \ne l_2$$
          Graphically, this happens when the two lines are parallel to each other (but have no overlap).
        </li>
      </ul>
      <br>
    </p>
    <div style="text-align:left">
      <h5> MagicGraph | Number of Solutions of a System of Equations </h5>
    </div>
    <p class="big mt-2">
      Use the MagicGraph below to participate in the exercise and record your answers. Tap on <i class="shuffler ma-1" /> button to move over to the next question.
      Tap on <i class="tester ma-1" /> button to check your answer.
      Tap on <i class="undoer ma-1" /> button to start over from the begining.
      <br>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <br>
    <h5> MagicGraph Navigation Buttons </h5>
    <p class="big mt-2">
      <i class="redoer ma-1" /> &mdash; is the 'Next' button. Tap on this button to move over to the next question. <br>
      <i class="tester ma-1" /> &mdash; is the 'Check Your Answer' button. Tap on this button to check your answer.<br>
      <i class="logor ma-1" />&mdash;  is the 'Get a Hint' button. Tap on this button to receive a hint on how to solve this problem. <br>
      <i class="undoer ma-1" /> &mdash; is the 'Start Over' button. Tap on this button to start over the exercise from the begining.
    </p>
    <h5> Markings & Indications </h5>
    <p class="big mt-2">
      The marks and indications are located on the top-left corner of the MagicGraph, and show your attempt status. <br>
      <i class="righter ma-1" /> &mdash; the right checkmark indicates that your attempted response was correct. <br>
      <i class="wrong ma-1" /> &mdash; this crossmark indicates that your attempted response was incorrect. <br>
      <i class="missed ma-1" /> &mdash; this exclaimation mark indicates an unattempted (missed) response. <br>
    </p>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'SystemofEquations',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'System of Linear Equations';
    this.$store.commit('navigation/changeTitle', title);
  /* this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Probability', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Probability of an Event', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
*/
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'System of Linear Equations',
          titleTemplate: '%s | Learn interactively',
          meta: [
                  {vmid: 'description', name: 'description', content: 'System of Linear Equations'}
                ]
        }
   },
}
</script>
